import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

const IndexPage = ({ siteTitle }) => {
  return (
    <Layout>
      <Helmet>
        <meta name="yandex-verification" content="9acc822cfddd2d29" />
      </Helmet>
      <SEO title="MTC Home Textile" />
      <div
        className="w-full h-screen bg-gray-300 flex items-center justify-center"
        style={{
          backgroundImage:
            "linear-gradient(to left bottom, #7a02df, #d400ae, #fa007f, #ff425b, #ff7b48)",
        }}
      >
        <div className="mb-20 text-center">
          <h1 className="text-7xl md:text-8xl font-bold text-white">
            MTC Home Textile
          </h1>
          <p className="text-3xl text-gray-900">Under Construction</p>
          <p>
            <a href="mailto:info@mtchometex.com">info@mtchometex.com</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
